.config-settings {
    padding: 4%;
    background-color: rgba(255, 255, 255, 0.226);
    border-radius: 0.5rem;
}

.dropdown, .input-group-text {
    display: inline-block;
    flex: 1 1 auto;
}

.dropdown-toggle {
    border-color: rgb(206, 212, 218);
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    width: 100% !important;
}