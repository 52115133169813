.dropdown-item {
    padding: 2%;
    font-weight: bold;
}

.form-control {
    height: inherit;
}

.rbt {
    display: inline-block;
    flex: 1 1 auto;
    width: 1%;
}

.rbt * {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rbt-token-removeable {
    padding-left: 1%;
}

.rbt-token {
    background-color: #E9ECEF;
    border: 0;
    border-radius: 2px;
    line-height: 1.7;
    color: inherit;
    display: inline-block;
    margin: 0 3px 3px 0;
    padding: 4px 7px;
    position: relative;
}
