.App .container{
    min-width: 90%;
    margin-left: auto;
}

.App-header {
    margin: 1%;
    text-align: center;
    font-weight: bold;
}


footer {
    margin: 1%;
    text-align: center;
    font-weight: bold;
    margin-top: 2%;
    font-size: 90%;
}

.card {
    border-radius: 0.5rem;
}
